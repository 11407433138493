@import "../../styles/style-functions.scss";
@import "../../styles/style-config.scss";

/* Login */

.login {
  .login-text {
    max-width: 500px;
    margin: 70px auto;
    padding: 0 20px;
    text-align: center;
    line-height: 23px;

    h2 {
      color: $color-highlight;
      @include font-size(26px);
      padding-bottom: 40px;
    }

    p {
      padding: 8px 0;
      @include font-size(18px);
    }
  }

  .login-form {
    width: 100%;
    margin: 0 auto;
    text-align: left;

    input,
    textarea {
      width: 100%;
      @include box-sizing(border-box);
      margin: 0 0 20px 0;
      padding: 20px;
    }

    .forgot-password {
      width: 100%;
      text-align: right;
      margin-top: -10px;
      margin-bottom: 10px;

      &:hover {
        text-decoration: underline;
      }
    }

    .error-form {
      color: red;
      padding-bottom: 5px;
      margin-left: 10px;
    }

    .loader-place {
      width: 500px;
    }
  }

  .no-account {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  button {
    background-color: $contact-button-submit-color-background;
    padding: 15px 20px;
    border: none;
    border-radius: 20px;
    color: $contact-button-submit-color-text;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: auto;
    margin-left: auto;
    display: block;
    @include font-size(16px);
    cursor: pointer;
    @include transition(border ease-in-out 0.2s, background ease-in-out 0.2s);
    width: 70%;

    @include md {
      width: 100%;
    }

    &:hover {
      background-color: $contact-button-submit-color-background-hover;
      color: $contact-button-submit-color-text-hover;
    }

  }

  .forgot-subtitle {
    margin-bottom: 25px;
  }

}
